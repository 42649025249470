<template>
  <div class="navbar-view">
    <div class="navbar-view-main">
      <div class="navbar-view-left">
        <div class="navbar-view-left-image">

        </div>
      </div>
      <div class="navbar-view-right">
        <div class="navbar-view-right-noe" v-if="home" @click="clickHome">
          <img src="../../assets/images/icon_shouye.png" class="navbar-view-right-noe-image" >
        </div>
        <div class="navbar-view-right-tow" @click="clickSearch" v-if="search">
          <img src="../../assets/images/icon_sousuo.png" class="navbar-view-right-tow-image">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:"navbar",
  data(){
    return{
      home:true,
      search:true,
    }
  },
  methods:{
    clickSearch(){
      this.$router.push({name:"Search"})
    },
    clickHome(){
      this.$router.replace({name:"Home"});
    }
  },
  mounted() {
    console.log(this.$route)
    if (this.$route.name === "Home"){
      this.home = false
      this.search = true
    }else if (this.$route.name === "Search"){
      this.home = false
      this.search = false
    }else {
      this.search = true
    }
  }
}
</script>

<style lang="less">
.navbar-view{
  width: 100vw;
  background: #004F9F;
  //min-height: 163px;
  box-shadow: 1px 3px 15px 0px rgba(100, 100, 100, 0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  .navbar-view-main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 25px 72px;
  }
  &-left{
    &-image{
      width: 211px;
      height: 110px;
      background-image: url("../../assets/images/logo.png");
      background-size: cover;
    }
  }
  &-right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &-noe{
      width: 60px;
      height: 60px;
      margin-right: 55px;
      &-image{
        width: 100%;
        height: 100%;
      }
    }
    &-tow{
      width: 60px;
      height: 60px;
      &-image{
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
