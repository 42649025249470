<template>
  <div>
    <navbar />
    <div class="article-view">
      <van-swipe @change="onChange" class="article-view-swipe" :autoplay="2000" :initial-swipe="current">
        <van-swipe-item v-for="item in arr" :key="item.id">
          <img :src="item.image" style="width: 100%;height: 100%;" @click="clickImage(item.target)">
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator">
            <div class="custom-indicator-left" >
              <img src="../assets/images/icon_zuo.png" class="custom-indicator-right-image"/>

            </div>
            <div class="custom-indicator-right">
              <img src="../assets/images/icon_you.png" class="custom-indicator-left-image"/>
            </div>
          </div>
        </template>
      </van-swipe>
    </div>
    <van-list
        v-model="isLoading"
        :finished="finished"
        finished-text="我是有底线的"
        @load="onLoad"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
    >
      <div class="article-view-div" v-if="articleArr.length !== 0">
        <div class="article-view-item" v-for="item in articleArr" :key="item.id" @click="clickArticle(item.id)">
          <div class="article-view-item-left">
            <img :src="item.thumbnail" style="width: 100%;height: 100%;">
          </div>
          <div class="article-view-item-right">
            <div class="article-view-item-right-title">{{item.title}}</div>
            <div class="article-view-item-right-subtitle">{{item.summary}}</div>
            <div class="article-view-item-right-date">{{item.publish_at}}</div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import navbar from "../components/NavBar"
export default {
  name:"article",
  data(){
    return{
      arr:[],
      current:0,
      articleArr:[],
      currentPage: 1,
      per_page:null,
      page:null,
      total:null,
      isLoading:false,
      finished: false,
      refreshing: false,//刷新
      error:false,
    }
  },
  components: { navbar },
  deactivated(){
    console.log("deactivated")
  },
  activated() {
    console.log("activated","==",this.$route.query.id);
    this.articleArr = [];
    this.getArticleById(this.$route.query.id)
    this.getArticleList(this.$route.query.id,1);
  },
  // mounted() {
  //   console.log("mounted","======");
  //   this.articleArr = [];
  //   this.getArticleById(this.$route.query.id);
  //   this.getArticleList(this.$route.query.id,1);
  // },
  methods:{
    onLoad(){
      setTimeout(()=>{
        this.getArticleList(this.$route.query.id,this.page +1);
      },800);
    },
    getArticleById(value){
      this.$http(`/article_categories/${value}`).then(res =>{
        this.arr = res.data.data.banners;
      })
    },
    getArticleList(value,page){
      this.isLoading = true;
      this.$http.get(`/articles?category_id=${value}&page=${page}&per_page=6`).then(res =>{
        // res.data.data.map(item =>{
        //   const date = new Date(item.publish_at.replace(/-/g, '/'))
        //  item.publish_at =  `${date.getFullYear()}年${Number(date.getMonth() + 1)}月${date.getDate()}号`
        // })
        if (res.data.meta.to === res.data.meta.total){
          this.finished = true
        }else {
          this.finished = false
        }
        if (Array.isArray(res.data.data)) {
          const list = res.data.data.filter(item => {
            return !this.articleArr.find(it =>it.id === item.id)
          })
          this.articleArr = this.articleArr.concat(list);
        }
        this.per_page = res.data.meta.per_page;
        this.total = res.data.meta.total;
        this.page = res.data.meta.current_page;
      }).finally(()=>{this.isLoading = false }).catch(()=>{this.error = true; })
    },
    clickImage(v){
      console.log(v,"==============");
      window.open(v);
    },
    clickArticle(v){
      this.$router.push({name:"ArticleInfo",query:{id:v}})
    }
  }
}
</script>
<style lang="less">
.article-view{
  margin-top: 160px;
  &-swipe{
    height: 478px;
    z-index: 6666;
  }
}
.custom-indicator {
  position: absolute;
  background: rgba(0, 0, 0, 0);
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //width: 100%;
  height: 478px;
  &-left{
    width: 61px;
    height: 142px;
    margin-left: 1000px;
    position: absolute;
    &-image{
      width: 100%;
      height: 100%;
    }
  }
  &-right{
    width: 61px;
    height: 142px;
    margin-right: 10px;
    margin-left: 20px;
    &-image{
      width: 100%;
      height: 100%;
    }
  }
}
.null-view{
  margin-top: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b0b1b9;
}
.article-view-div{
  padding: 57px 29px;
}
.article-view-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  height: 275px;
  margin-bottom: 45px;
  &-left{
    width: 325px;
    height: 275px;
    background: rgba(235, 235, 235, 1);
    flex-shrink: 0;
  }
  &-right{
    flex: 1;
    padding: 38px 41px 0 38px;
    height: 240px;
    background: rgba(235, 235, 235, 1);
    overflow: hidden;
    &-title{
      font-size: 36px;
      font-weight: 400;
      color: #242424;
      margin-bottom: 24px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &-subtitle{
      font-size: 32px;
      font-weight: 400;
      color: #6C6D6E;
      margin-bottom: 50px;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    &-date{
      font-size: 32px;
      font-weight: 400;
      color: #787878;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
}
</style>
